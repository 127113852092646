.alert {
    padding: 15px 30px !important;
    margin-top: 20px !important;
    border: 1px solid transparent !important;
    border-radius: 4px !important;
    position: fixed !important;
    top: 0 !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: auto !important;
    max-width: 350px !important;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1) !important;
    z-index: 1050 !important;
}

.alert-message {
    flex-grow: 1 !important;
    margin-right: 10px !important;
}

.close {
    padding: 0.2rem 0.5rem !important;
    color: #000 !important;
    font-size: 24px !important;
    background: transparent !important;
    border: none !important;
    cursor: pointer !important;
}

.alert-success {
    color: #155724 !important;
    background-color: #d4edda !important;
    border-color: #c3e6cb !important;
}

.alert-error {
    color: #721c24 !important;
    background-color: #f8d7da !important;
    border-color: #f5c6cb !important;
}

.alert-warning {
    color: #856404 !important;
    background-color: #fff3cd !important;
    border-color: #ffeeba !important;
}

.alert-info {
    color: #0c5460 !important;
    background-color: #d1ecf1 !important;
    border-color: #bee5eb !important;
}
