.horizontal-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  background-color: #fbf7f3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 72px;
  z-index: 999;
}

.notification-bell {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.notification-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #ff0000;
  color: #fff;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.75rem;
  height: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.account-section {
  display: flex;
  align-items: center;
}

.account-info {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.username {
  color: #3e3f66;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .username {
    display: none;
  }
}

.account-dropdown-icon {
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(180deg);
}

.account-modal {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 250px;
  border-radius: 6px;
  overflow: hidden;
  top: 62px;
  right: 0;
  transform: translateX(-10%);
  color: #989898;
}

.modal-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.modal-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.modal-item:hover {
  background-color: #32b3c226;
}

.language-section {
  padding: 10px;
  border-top: 1px solid #eee;
}

.language-title {
  margin: 0;
  padding-bottom: 5px;
  font-size: 14px;
  color: #333;
}

.language-option {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  cursor: pointer;
}

.language-option img {
  width: 24px;
  height: auto;
}

.language-option:not(.selected):hover {
  text-decoration: underline;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.top-bar-links {
  text-decoration: none;
  color: #989898;
}

.top-bar-links:hover {
  color: #32b3c2;
}

.top-bar-language-switcher {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  margin-right: 20px;
}

.language-flag {
  width: 20px;
  height: auto;
}

.language-dropdown-icon {
  transition: transform 0.3s ease;
}

.language-dropdown {
  position: absolute;
  top: 100%;
  background-color: #fbf7f3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 5px;
  z-index: 1000;
}

.language-dropdown .language-option {
  padding: 10px;
  width: 100%;
  text-align: left;
  white-space: nowrap;
}

.language-dropdown .language-option:hover {
  background-color: #f0f0f0;
}
