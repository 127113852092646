.mobile-money-transaction-alert {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 600px;
    background: #FFFFFF;
    color: #333;
    border-radius: 10px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 1050;
    display: flex;
    flex-direction: column;
}

.transaction-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.transaction-header h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.close-btn {
    font-size: 24px;
    border: none;
    background: none;
    cursor: pointer;
}

.transaction-details p {
    font-size: 16px;
    line-height: 1.5;
    margin: 5px 0;
}

.alert-success {
    border-left: 5px solid #4CAF50;
}

.alert-error {
    border-left: 5px solid #F44336;
}
