.account-section {
    position: relative;
}

.notification-modal {
    position: absolute;
    top: 50px;
    right: 20px;
    width: 300px;
    max-height: 600px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1050;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 8px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    overflow: hidden;
}

.notification-modal.open {
    opacity: 1;
    visibility: visible;
}

.notification-header {
    padding: 10px 15px;
    background-color: #f9f9f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.notification-header h3 {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
}

.close-button img {
    width: 20px;
}

.notification-list {
    overflow-y: auto;
    max-height: calc(100% - 50px);
}

.notification-item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #eee;
}

.notification-item:last-child {
    border-bottom: none;
}

.notification-item .avatar {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ccc;
}

.notification-item .notification-content {
    flex: 1;
    min-width: 0;
}

.notification-item .notification-content .notification-message {
    font-size: 14px;
    color: #333;
    font-weight: normal;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.notification-item .notification-content .notification-date {
    font-size: 12px;
    color: #999;
    margin-top: 5px;
}

.notification-action {
    margin-left: auto;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid transparent;
    background-color: #32B3C2;
}

.notification-action.accept {
    background-color: #28a745;
    color: #fff;
}

.notification-action.reject {
    background-color: #dc3545;
    color: #fff;
}

.notification-action:hover {
    opacity: 0.85;
}

.view-more {
    padding: 10px;
    text-align: center;
    background-color: #f9f9f9;
    cursor: pointer;
    font-size: 14px;
}

.loading-message, .error-message {
    padding: 10px 15px;
    text-align: center;
    color: #333;
    font-size: 14px;
}

.date-header {
    padding: 10px 15px;
    color: #333;
    font-size: 14px;
    font-weight: bold;
}

.notification-item.info .notification-message {
    color: #31708f;
}

.notification-item.failed .notification-message {
    color: #a94442;
}

.notification-item.success .notification-message {
    color: #3c763d;
}



