.survey-list-container {
  margin-top: 2%;
  width: 100%;
  padding-bottom: 40px;
  margin-bottom: 5%;
}

.survey-main-heading {
  font-size: 28px;
  color: #2c3e50;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.survey-header,
.survey-card {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.survey-header {
  background-color: #f3f3f3;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  margin-bottom: 3px;
}

.header-title {
  color: #34495e;
  text-align: center;
  flex: 1;
  padding: 0 10px;
}

.header-title,
.survey-description,
.survey-action {
  flex: 1;
  padding: 0 10px;
}


.profile-completion-cta-main-text {
  font-size: 18px;
  font-weight: 400;
  color: #7a5e2a;
}

.profile-completion-cta-subtext {
  font-size: 16px;
  font-weight: 400;
  color: #7a5e2a;
}

.profile-completion-cta-container{
  padding: 10px;
  text-align: center;
}

.header-title:first-child {
  flex: 2;
}

.survey-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.survey-card:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  transform: translateY(-3px);
}

.survey-description {
  font-size: 16px;
  font-weight: 500;
  color: #2c3e50;
  padding: 0 10px;
  text-align: center;
}

.survey-text {
  flex: 2;
}

.start-survey-button {
  padding: 12px 30px;
  background-color: #3e3f66;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.start-survey-button:hover {
  background-color: #32B3C2;
  transform: translateY(-2px);
}

.survey-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-top: 20px;
}

.survey-footer button {
  padding: 10px 20px;
  background-color: #ecf0f1;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.survey-footer button:hover {
  background-color: #bdc3c7;
}

.page-number.active {
  background-color: #3498db;
  color: white;
}

.pagination button img {
  width: 20px;
  height: 20px;
}

.no-surveys {
  text-align: center;
  padding: 100px 20px;
  background-color: #fff3cd;
  border: 2px solid #ffeeba;
  border-radius: 8px;
  margin-bottom: 20px;
}

.no-surveys .info-section {
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
}

.no-surveys .info-icon {
  height: 24px;
  width: 24px;
  margin-right: 5px;
}

.no-surveys-text {
  font-size: 16px;
  font-weight: 700;
  color: #7a5e2a;
}

.no-surveys .learn-more-text {
  font-size: 14px;
  color: #7a5e2a;
  font-weight: 500;
  cursor: pointer;
}

.info-section {
  display: flex;
  align-items: center;
}

.info-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.learn-more-text {
  font-size: 14px;
  color: #3e3f66;
  font-weight: 500;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.page-number,
.pagination button {
  padding: 8px 12px;
  margin: 0 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.page-number.active,
.pagination button:hover {
  background-color: #32b3c2;
  color: white;
  border-color: #32b3c2;
}

.profile-completion-cta {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff3cd;
  border: 2px solid #ffeeba;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  color: #856404;
}

.bold-cta {
  font-weight: bold;
  font-size: 18px;
}

.update-profile-button {
  padding: 12px 25px;
  background-color: transparent;
  color: #856404;
  border: 2px solid #856404;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.update-profile-button:hover {
  background-color: #856404;
  color: white;
}

@media (max-width: 768px) {
  .survey-header,
  .survey-card {
    flex-direction: column;
    padding: 10px;
    align-items: flex-start;
  }


  .survey-text {
    display: none;
  }

  .survey-description,
  .survey-title {
    margin-left: 0;
  }

  .survey-detail-amount,
  .survey-detail,
  .survey-action {
    text-align: left;
    width: 100%;
    margin: 5px 0;
  }

  .survey-card {
    margin-bottom: 15px;
  }
}
