.mobile-topup-hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #3E3F66;
    background-image: url('../Images/hero.svg'), linear-gradient(to right, #32B3C2, #3E3F66);
    background-size: cover;
    color: white;
    border-radius: 13px;
    height: 150px;
    width: 99%;
    margin-top: 2%;
}

.mobile-topup-hero-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.mobile-topup-hero-heading {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.mobile-topup-account-balance {
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.mobile-topup-minimum-withdrawal {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 1rem;
}

.mobile-topup-hero-image {
    margin-left: auto;
    padding-right: 20px;
    height: calc(100% - 40px);
}

.mobile-topup-hero-text {
    margin-top: 15px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .mobile-topup-hero {
        flex-direction: column;
        height: auto;
        text-align: center;
    }

    .mobile-topup-hero-content {
        flex-direction: column;
        align-items: center;
    }

    .mobile-topup-hero-heading {
        font-size: 14px;
    }

    .mobile-topup-account-balance {
        font-size: 24px;
    }

    .mobile-topup-minimum-withdrawal {
        font-size: 12px;
    }

    .mobile-topup-hero-image {
        margin-left: 0;
        margin-top: 10px;
        padding-right: 0;
        height: auto;
    }
}

@media (max-width: 480px) {
    .mobile-topup-hero-heading {
        font-size: 12px;
    }

    .mobile-topup-account-balance {
        font-size: 20px;
    }

    .mobile-topup-minimum-withdrawal {
        font-size: 10px;
    }
}
