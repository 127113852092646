.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  background: url('../Images/sidebar-pattern.svg') no-repeat center center;
  background-size: cover;
  z-index: 10;
  color: #FDF3E6;
  padding: 20px 15px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 0.3s;
}

.logout {
  background: none;
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
  padding: 10px;
  width: calc(100% - 40px);
  cursor: pointer;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.logout:hover {
  background-color: #FFFFFF;
  color: #0d6769;
}

.sidebar-bottom-icons {
  width: 24px;
  height: 24px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
  transition: filter 0.3s;
}

.logout:hover .sidebar-bottom-icons {
  filter: brightness(0) saturate(100%) invert(36%) sepia(85%) saturate(1352%) hue-rotate(146deg) brightness(90%) contrast(97%);
}

.sidebar.collapsed {
  width: 80px;
}

.logo {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-logo {
  width: 150px;
  height: auto;
  display: inline-block;
}

.collapse-button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #ffffff;
  padding: 10px 0;
  text-align: right;
  width: 100%;
  transition: color 0.3s, background-color 0.3s;
}

.collapse-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fdf3e6;
}

.collapse-button svg {
  width: 20px;
  height: 20px;
  color: #ffffff;
}

.sidebar.collapsed .collapse-button {
  justify-content: center;
}

.navigation {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 20px;
}

.navigation ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.navigation li {
  width: 100%;
  margin: 7px 0;
  position: relative;
}

.navigation li a {
  text-decoration: none;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 20px;
  transition: color 0.3s, background-color 0.3s;
}

.navigation li a img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
  transition: filter 0.3s;
}

.navigation li.active a,
.navigation li:hover a {
  background-color: #F9F9F9;
  color: #0d6769;
  border-radius: 5px;
}

.navigation li.active a img,
.navigation li:hover a img {
  filter: brightness(0) saturate(100%) invert(36%) sepia(85%) saturate(1352%) hue-rotate(146deg) brightness(90%) contrast(97%);
}

.admin-page::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #32B3C2;
  border-radius: 5px 0 0 5px;
}

.invite-friends-container {
  margin-top: auto;
  padding: 20px;
  background-color: rgba(253, 243, 230, 0.25);
  border-radius: 10px;
  text-align: center;
  width: calc(100% - 30px);
  font-size: 12px;
}

.invite-now-button {
  background-color: #1F1E30;
  color: #FDF3E6;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s;
  width: calc(100% - 40px);
  margin: auto;
  display: block;
}

.invite-now-button:hover {
  background-color: #0d6769;
}

.sidebar-footer {
  align-self: center;
  padding-bottom: 20px;
}

.sidebar-footer button {
  background: none;
  border: none;
  color: #FDF3E6;
  padding: 10px;
  width: calc(100% - 40px);
  cursor: pointer;
  text-align: left;
  margin-bottom: 10px;
}

.sidebar-footer button:hover {
  text-decoration: underline;
}

.sidebar-icon, .sidebar-bottom-icons {
  width: 24px;
  height: 24px;
}

@media (max-width: 768px) {
  .sidebar {
    width: 80%;
    max-width: 350px;
  }

  .collapse-button {
    display: none;
  }

  .sidebar_close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: transparent;
    color: #000000;
    z-index: 12;
  }

  .sidebar_close svg {
    width: 34px;
    height: 34px;
    color: #32B3C2;
  }
}

@media (min-width: 769px) {
  .sidebar_close {
    display: none;
  }
}
