.mobile-top-up-form {
    margin-top: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.mobile-top-up-form__content {
    width: 100%;
    background: #FFFFFF;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    margin-top: 20px;
    padding: 25px;
}

.mobile-top-up-form__step {
    text-align: center;
}

.mobile-top-up-form__step--narrow {
    max-width: 600px;
    margin: 0 auto;
}

.mobile-top-up-form__step h2 {
    font-size: 26px;
    font-weight: 700;
    color: #3E3F66;
    margin-bottom: 20px;
}

.mobile-top-up-form__step p {
    font-size: 14px;
    color: #666;
    margin: 10px 0;
}

.mobile-top-up-form__input-group {
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
    max-width: 500px;
    margin: 0 auto 20px auto;
    position: relative;
}

.mobile-top-up-form__input-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
    color: #3E3F66;
    text-align: center;
}

.mobile-top-up-form__input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.mobile-top-up-form__input-wrapper input {
    width: 100%;
    border: 1px solid #32b3c2;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    background-color: rgba(228, 228, 228, 0.5);
}

.mobile-top-up-form__clear-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #32b3c2;
    cursor: pointer;
    font-size: 14px;
}

.mobile-top-up-form__buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.mobile-top-up-form__country-code-select {
    margin-right: 10px;
    padding: 12px;
    border: 1px solid #32b3c2;
    border-radius: 8px;
    background-color: rgba(228, 228, 228, 0.5);
    font-size: 16px;
}

.mobile-top-up-form__button {
    background-color: #32B3C2;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 8px;
    margin: 0 10px;
}

.mobile-top-up-form__button:hover {
    background-color: #2a919d;
}

.mobile-top-up-form__button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

.mobile-top-up-form__inputs-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 60%;
    max-width: 600px;
    margin: 0 auto;
}

.mobile-top-up-form__amount-input-group,
.mobile-top-up-form__converted-amount-group {
    width: 48%;
    text-align: left;
}

.mobile-top-up-form__amount-input-group label,
.mobile-top-up-form__converted-amount-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
    color: #3E3F66;
    text-align: center;
}

.mobile-top-up-form__amount-input-group input,
.mobile-top-up-form__converted-amount-group input {
    width: 100%;
    border: 1px solid #32b3c2;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    background-color: rgba(228, 228, 228, 0.5);
}

.mobile-top-up-form__converted-amount-group input {
    cursor: not-allowed;
}

.mobile-top-up-form__input-error {
    border-color: #ff4b4b;
}

.mobile-top-up-form__error-message {
    color: #ff4b4b;
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
}

.mobile-top-up-form__conversion-info {
    text-align: center;
}

.mobile-top-up-form__minimum-withdrawal {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
}

.mobile-top-up-form__request-payout-btn {
    width: 200px;
    padding: 15px;
    background-color: #32B3C2;
    color: #fff;
    border: none;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 auto;
}

.mobile-top-up-form__request-payout-btn:hover {
    background-color: #2a919d;
}

.mobile-top-up-form__request-payout-btn:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

.mobile-top-up-form__operator-info {
    text-align: center;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mobile-top-up-form__operator-logo {
    width: 120px;
    height: auto;
    margin-bottom: 10px;
    border-radius: 8px;
}

.mobile-top-up-form__operator-name {
    font-size: 20px;
    color: #3E3F66;
    font-weight: bold;
    margin-top: 10px;
}
