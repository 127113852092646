.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fbf7f3;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-bar-container {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 3px;
    overflow: hidden;
    background-color: #f0f0f0;
}

.loading-bar {
    height: 5px;
    background: linear-gradient(to right, #32B3C2, #56c5d2);
    width: 0%;
    animation: loadingAnimation 2.5s cubic-bezier(0.4, 0, 0.2, 1) infinite,
    pulsate 1.2s ease-in-out infinite;
}

@keyframes loadingAnimation {
    0% { width: 0%; transform: translateX(0); }
    30% { width: 40%; transform: translateX(0); }
    60% { width: 70%; transform: translateX(0); }
    80% { width: 85%; transform: translateX(-10%); }
    100% { width: 100%; transform: translateX(0); }
}

@keyframes pulsate {
    0% { opacity: 1; }
    50% { opacity: 0.8; }
    100% { opacity: 1; }
}
