.dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fbf7f3;
  padding-bottom: 10%;
  position: relative;
  z-index: 1;
}

.dashboard_container {
  flex: 1;
  flex-grow: 1;
  overflow-y: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  z-index: 2;
}

.dashboard_containerBody {
  flex: 1;
  padding-left: 260px;
  min-height: 100vh;
  position: relative;
  z-index: 3;
}

.dashboard_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 260px;
  z-index: 1000; /* High z-index to ensure it's above other elements */
}

/* Styles for the TopBar */
.topbar {
  position: sticky;
  top: 0;
  z-index: 999; /* Just below the sidebar */
}

/* Hide the dashboard_sidebar on mobile devices */
@media (max-width: 768px) {
  .dashboard_sidebar {
    display: none;
  }
  
  .dashboard_containerBody {
    flex: 1;
    flex-direction: column;
    padding: 1rem;
  }
  
  /* When sidebar is open on mobile */
  .dashboard_sidebar.open {
    display: block;
    z-index: 1001; /* Higher than the regular sidebar to appear above everything when open */
  }
}