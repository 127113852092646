.mobile-topup {
    background-color: #fbf7f3;
}

.main-mobile-topup-container {
    display: flex;
    min-height: 100vh;
    padding-bottom: 5%;
}

.mobile-topup-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 30px;
}

.mobile-topup-heading {
    color: #3E3F66;
    font-size: 24px;
    font-weight: 700;
}

.mobile-topup-paragraph {
    color: #000000;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .mobile-topup-content {
        padding: 20px;
    }

    .mobile-topup-heading {
        font-size: 20px;
    }

    .mobile-topup-paragraph {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .mobile-topup-content {
        padding: 15px;
    }

    .mobile-topup-heading {
        font-size: 18px;
    }

    .mobile-topup-paragraph {
        font-size: 10px;
    }
}
