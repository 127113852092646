.header-main {
  position: fixed;
  top: 0;
  left: 0;
  align-items: left;
  width: 20%;
  height: 20%;
  padding-top: 2%;
  padding-left: 5%;
  z-index: 1000;
}

@media screen and (max-width: 576px) {
  .header-main {
    display: none;
    width: 60%;
    padding-top: 10%;
    padding-left: 10%;
  }

  .logo {
    width: 80%;
    height: auto;
  }
}
