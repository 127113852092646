.main-survey-result {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fbf7f3;
    color: #333;
    padding: 20px;
}

.survey-result-container {
    text-align: center;
    max-width: 700px;
    padding: 40px;
    background: white;
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
    border-radius: 12px;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
}

.survey-result-container:hover {
    transform: scale(1.02);
}

.survey-result-image {
    display: block;
    margin: 0 auto 20px auto;
    width: 150px;
    border-radius: 50%;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.survey-result-title {
    color: #32B3C2;
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: bold;
}

.survey-result-content {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 1.8;
}

.survey-result-text p {
    margin: 10px 0;
    color: #555;
    font-size: 16px;
}

.survey-result-text p:first-child {
    font-weight: bold;
}

.survey-result-text p:last-child {.main-survey-result {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fbf7f3;
    color: #333;
    padding: 20px;
}

    .card-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .survey-result-container {
        text-align: center;
        max-width: 700px;
        padding: 40px;
        background: white;
        box-shadow: 0 10px 20px rgba(0,0,0,0.2);
        border-radius: 12px;
        transform: scale(1);
        transition: transform 0.3s ease-in-out;
    }

    .survey-result-container:hover {
        transform: scale(1.02);
    }

    .survey-result-image {
        display: block;
        margin: 0 auto 20px auto;
        width: 150px;
        border-radius: 50%;
        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    }

    .survey-result-title {
        color: #32B3C2;
        font-size: 28px;
        margin-bottom: 20px;
        font-weight: bold;
    }

    .survey-result-content {
        margin-bottom: 40px;
        font-size: 18px;
        line-height: 1.8;
    }

    .survey-result-text p {
        margin: 10px 0;
        color: #555;
        font-size: 16px;
    }

    .survey-result-text p:first-child {
        font-weight: bold;
    }

    .survey-result-text p:last-child {
        margin-top: 20px;
        font-style: italic;
    }

    .survey-result-link {
        display: inline-block;
        background-color: #32B3C2;
        color: white;
        text-decoration: none;
        padding: 12px 30px;
        border-radius: 8px;
        transition: background-color 0.3s ease;
        font-weight: bold;
        box-shadow: 0 5px 15px rgba(0,0,0,0.2);
    }

    .survey-result-link:hover {
        background-color: #258f9e;
        box-shadow: 0 5px 15px rgba(0,0,0,0.4);
    }

    /* Responsive Styles */
    @media (max-width: 768px) {
        .survey-result-container {
            padding: 20px;
        }

        .survey-result-title {
            font-size: 24px;
        }

        .survey-result-content {
            font-size: 16px;
        }

        .survey-result-text p {
            font-size: 14px;
        }

        .survey-result-link {
            padding: 10px 20px;
            font-size: 14px;
        }
    }

    @media (max-width: 480px) {
        .survey-result-container {
            padding: 15px;
        }

        .survey-result-title {
            font-size: 20px;
        }

        .survey-result-content {
            font-size: 14px;
        }

        .survey-result-text p {
            font-size: 12px;
        }

        .survey-result-link {
            padding: 8px 15px;
            font-size: 12px;
        }
    }

    margin-top: 20px;
    font-style: italic;
}

.survey-result-link {
    display: inline-block;
    background-color: #32B3C2;
    color: white;
    text-decoration: none;
    padding: 12px 30px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    font-weight: bold;
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.survey-result-link:hover {
    background-color: #258f9e;
    box-shadow: 0 5px 15px rgba(0,0,0,0.4);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .survey-result-container {
        padding: 20px;
    }

    .survey-result-title {
        font-size: 24px;
    }

    .survey-result-content {
        font-size: 16px;
    }

    .survey-result-text p {
        font-size: 14px;
    }

    .survey-result-link {
        padding: 10px 20px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .survey-result-container {
        padding: 15px;
    }

    .survey-result-title {
        font-size: 20px;
    }

    .survey-result-content {
        font-size: 14px;
    }

    .survey-result-text p {
        font-size: 12px;
    }

    .survey-result-link {
        padding: 8px 15px;
        font-size: 12px;
    }
}
