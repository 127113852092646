.create-opportunities-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Increase this value */
}

.create-opportunities-modal {
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    z-index: 10000; /* Ensure this is higher than the overlay */
    position: relative; /* Add this */
}

/* Modal box */
.edit-profile-modal {
    position: relative;
    background: #FFF;
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    padding: 20px;
    padding-left: 70px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    overflow: hidden;
}

/* Sidebar pattern for modal */
.edit-profile-modal::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    background-image: url('../Images/sidebar-pattern.svg');
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.form-input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    outline: none;
    transition: all 0.3s ease;
}

.form-input:focus {
    border-color: #32b3c2;
    box-shadow: 0 0 5px rgba(50, 179, 194, 0.5);
}

.form-input:disabled,
.form-input.read-only-input,
.select-dropdown .react-select__control--is-disabled {
    background-color: #f0f0f0;
    border-color: #ddd;
    color: #888;
    cursor: not-allowed;
    opacity: 0.7;
}

.form-input:disabled::placeholder,
.form-input.read-only-input::placeholder {
    color: #999;
}

/* Modal header styling */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Close button styling */
.close-button {
    background: transparent;
    border: none;
    cursor: pointer;
}

.close-button img {
    width: 24px;
    height: 24px;
}

/* Modal content padding */
.modal-content {
    padding-bottom: 20px;
}

/* Form group styling */
.edit-profile-form-group {
    margin-bottom: 20px;
}

.edit-profile-form-group label {
    display: block;
    margin-bottom: 5px;
}

.edit-profile-form-group input[type="text"],
.edit-profile-form-group select {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #FFFFFF;
}

.edit-profile-form-group input[type="text"]:disabled {
    background-color: #f9f9f9;
    cursor: not-allowed;
}

.edit-profile-form-group select:disabled {
    background-color: #f9f9f9;
    cursor: not-allowed;
}

/* Input error state */
.input-error-profile {
    border: 1px solid #D8000C !important;
}

/* Error message */
.error-message-profile {
    color: #D8000C;
    font-size: 12px;
    margin-top: 5px;
}

/* Modal header text */
.modal-header h2 {
    font-size: 25px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin: 0;
}

/* Paragraph styling */
.edit-profile-modal-paragraph {
    font-size: 14px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #4B4B4B;
    margin-top: -3%;
    margin-bottom: 8%;
}

/* Save button styling */
.save-button {
    width: 100%;
    padding: 10px 0;
    background: #32B3C2;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Phone input wrapper */
.phone-input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

/* Adjust the country code dropdown width */
.phone-code-select {
    width: 20%;
    margin-right: 10px;
}

/* Adjust phone input to fit next to the country code */
input[name='phone'] {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #FFFFFF;
}

/* Check phone button styling */
.phone-check-button {
    padding: 8px 15px;
    margin-left: 10px;
    background-color: #32B3C2;
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.phone-check-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.phone-check-button:hover:not(:disabled) {
    background-color: #279da8;
}

/* Error message styling */
.error-message-profile {
    color: #D8000C;
    font-size: 12px;
    margin-top: 5px;
    text-align: left;
}

/* Dropdown select styling */
.edit-profile-form-group .select__control {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 14px;
}

.edit-profile-form-group .select__control--is-focused {
    border-color: #32B3C2;
    box-shadow: none;
}

.edit-profile-form-group .select__multi-value {
    background-color: #E0F7FA;
    border-radius: 3px;
    padding: 2px 5px;
    margin: 2px;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .edit-profile-modal {
        padding: 20px;
        padding-left: 20px;
        margin-left: 2%;
        margin-right: 2%;
    }

    .edit-profile-modal::before {
        display: none; /* Hide sidebar pattern on mobile */
    }

    .modal-header h2 {
        font-size: 20px;
    }

    .edit-profile-form-group input[type="text"],
    .edit-profile-form-group select {
        width: 100%;
    }

    .edit-profile-modal-paragraph {
        margin-top: 5%;
        margin-bottom: 10%;
        font-size: 13px;
        text-align: center;
    }

    .save-button {
        padding: 10px 0;
        font-size: 16px;
    }

    .close-button img {
        width: 20px;
        height: 20px;
    }

    .phone-input-wrapper {
        width: 100%;
    }

    .phone-code-select {
        width: 30%;
    }

    input[name='phone'] {
        width: 70%;
    }
}
