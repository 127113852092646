.footer-v2 {
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  height: 52px;
  padding-left: 350px;
}

.footer-v2__content {
  width: 90%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 50px;
}

@media (max-width: 768px) {
  .footer-v2 {
    padding-left: 1rem;
    height: auto;
    padding: 20px 0;
  }

  .footer-v2__content {
    flex-direction: column;
    align-items: center;
    padding-right: 0;
    text-align: center;
  }

  .footer-v2__right {
    margin-bottom: 10px;
  }
}

.footer-v2__left {
  color: #888888;
  font-size: 14px;
}

.footer-v2__right {
  display: flex;
  gap: 20px;
}

.footer-v2__link {
  color: #888888;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
}

.footer-v2__link:hover {
  color: #32b3c2;
}
