.pre-screening {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #fbf7f3;
    padding: 20px; /* Added padding to avoid content touching screen edges */
}

.pre-screening__content {
    max-width: 800px;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: auto;
    overflow-y: auto;
}

.pre-screening__heading {
    font-size: 24px;
    margin-bottom: 20px;
    color: #3E3F66;
    text-align: center; /* Center the heading */
}

.pre-screening__paragraph {
    color: #3E3F66;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px; /* Add spacing after paragraph */
}

.pre-screening__error-message {
    color: red;
}

.pre-screening__honeypot {
    display: none;
}

.pre-screening__question {
    margin-bottom: 30px; /* Increased margin to give more space between questions */
}

.pre-screening__question h3 {
    font-size: 18px;
    color: #3E3F66;
    margin-bottom: 10px;
    position: relative;
}

.pre-screening__question h3::after {
    content: '*';
    color: #D8000C;
    margin-left: 5px;
}

.pre-screening__custom-radio,
.pre-screening__custom-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.pre-screening__hidden-input {
    display: none;
}

.pre-screening__custom-radio label,
.pre-screening__custom-checkbox label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
}

.pre-screening__custom-radio label::before,
.pre-screening__custom-checkbox label::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 2px solid #32B3C2;
    background-color: #fff;
    cursor: pointer;
}

.pre-screening__custom-radio label::before {
    border-radius: 50%;
}

.pre-screening__custom-checkbox label::before {
    border-radius: 4px;
}

.pre-screening__custom-radio input[type="radio"]:checked + label::before,
.pre-screening__custom-checkbox input[type="checkbox"]:checked + label::before {
    background-color: #32B3C2;
}

.pre-screening__custom-checkbox label {
    position: relative;
}

.pre-screening__custom-checkbox label::after {
    content: '×';
    font-size: 14px;
    color: white;
    line-height: 1;
    display: block;
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translate(0, -50%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.pre-screening__custom-checkbox input[type="checkbox"]:checked + label::after {
    opacity: 1;
}

.pre-screening__text-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 10px;
    font-size: 16px;
}

.pre-screening__form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.pre-screening__button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.pre-screening__button--save {
    background-color: #32B3C2;
    color: #fff;
    transition: background-color 0.3s ease;
}

.pre-screening__button--save:hover {
    background-color: #2a919d;
}

.pre-screening__button--cancel {
    background-color: #ccc;
    color: #000;
    transition: background-color 0.3s ease;
}

.pre-screening__button--cancel:hover {
    background-color: #999;
}

.pre-screening__no-questions {
    text-align: center;
    margin-top: 20px;
}

/* Modal improvements */
.pre-screening__confirm-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px; /* Add padding to prevent modal from touching screen edges */
}

.pre-screening__confirm-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    max-height: 90vh; /* Limit modal height to avoid overflow */
    overflow-y: auto; /* Enable scrolling for large content */
}

.pre-screening__confirm-modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.pre-screening__button--confirm,
.pre-screening__button--deny {
    flex: 1;
    margin: 0 10px;
    padding: 10px 0;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
}

.pre-screening__button--confirm {
    background-color: #d9534f;
    transition: background-color 0.3s ease;
}

.pre-screening__button--confirm:hover {
    background-color: #c9302c;
}

.pre-screening__button--deny {
    background-color: #5bc0de;
    transition: background-color 0.3s ease;
}

.pre-screening__button--deny:hover {
    background-color: #31b0d5;
}

.pre-screening__device-restriction {
    text-align: center;
}

.pre-screening__link-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pre-screening__link-input {
    width: 70%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 16px;
}

.pre-screening__copy-button {
    background-color: #32B3C2;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pre-screening__copy-button:hover {
    background-color: #2a919d;
}

.pre-screening__copy-success {
    color: #32B3C2;
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
    animation: fade-in-out 3s ease-in-out;
}

@keyframes fade-in-out {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
}

.pre-screening__supported-devices {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.pre-screening__device {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #3E3F66;
}

.pre-screening__device svg {
    margin-right: 8px;
}

@media (max-width: 768px) {
    .pre-screening__content {
        padding: 15px;
        margin: 0 10px;
    }

    .pre-screening__heading {
        font-size: 20px;
    }

    .pre-screening__paragraph {
        font-size: 14px;
    }

    .pre-screening__question h3 {
        font-size: 16px;
    }

    .pre-screening__text-input {
        font-size: 14px;
    }

    .pre-screening__form-buttons {
        flex-direction: column;
        align-items: center;
    }

    .pre-screening__button {
        margin-bottom: 10px;
        width: 100%;
    }

    .pre-screening__confirm-modal-content {
        max-width: 90%;
        max-height: 80vh; /* Adjust modal size for smaller screens */
    }
}
