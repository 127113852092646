.language-switcher {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 10px;
  right: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.language-flag {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.react-select__control {
  border: none;
  box-shadow: none;
}

.react-select__single-value {
  display: flex;
  align-items: center;
}

.react-select__option {
  display: flex;
  align-items: center;
}
